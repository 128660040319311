<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>View Course - {{ course.name }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="blue lighten-4 blue--text"
                  v-on="on"
                  @click="$refs.courseDialog.openForm(course)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Course</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="mb-6"></v-divider>
      <div class="d-flex justify-end mb-6">
        <v-btn color="accent" depressed @click="addDay">Add A Day</v-btn>
      </div>
      <v-sheet elevation="2" outlined class="rounded">
        <v-expansion-panels mulitple focusable v-if="course.days.length > 0">
          <v-expansion-panel v-for="(day, index) in course.days" :key="day.id">
            <v-expansion-panel-header>
              <div class="d-flex justify-space-between align-center">
                <div>
                  Day {{ index + 1 }}
                  {{ day.hasOwnProperty("id") ? "" : "(Unsaved)" }}
                </div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      class="mr-4"
                      v-on="on"
                      @click="openDelete(day, index)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Day</span>
                </v-tooltip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-4">
              <DayForm :day.sync="day" :index="index" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="pa-5" v-else>
          <p>There are currently no days</p>
          <v-btn color="accent" depressed @click="addDay">Add A Day</v-btn>
        </div>
      </v-sheet>
      <div class="d-flex justify-end mt-6">
        <v-btn color="accent" depressed @click="addDay">Add A Day</v-btn>
      </div>
    </v-container>
    <CourseDialog ref="courseDialog" />
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Day</v-card-title>
        <v-card-text>Are you sure you want to delete this day?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CourseDialog from "./CourseDialog";
import DayForm from "./DayForm";

export default {
  components: {
    CourseDialog,
    DayForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Courses",
          disabled: false,
          exact: true,
          to: {
            name: "module-flightmode-courses",
            params: { id: this.$route.params.id },
          },
        },
        {
          text: "View",
          disabled: true,
        },
      ],
      formFields: {
        months: ["October", "November", "December"],
        years: ["2020", "2021", "2022"],
        categories: ["Fire", "Wind"],
        themes: ["Honesty", "Integrity", "Trust"],
      },
      deleteDialog: {
        open: false,
        loading: false,
        day: {},
        index: null,
      },
    };
  },

  computed: {
    course() {
      return this.$store.state.flightmode.courses["course"];
    },
  },

  methods: {
    addDay() {
      this.course.days.push({});
    },

    openDelete(day, index) {
      this.deleteDialog.day = day;
      this.deleteDialog.open = true;
      this.deleteDialog.index = index;
    },

    resetDelete() {
      this.deleteDialog.loading = false;
      this.deleteDialog.open = false;
      this.deleteDialog.day = {};
      this.deleteDialog.index = null;
    },

    saveDelete() {
      this.deleteDialog.loading = true;

      let payload = {
        isSaved: true,
      };

      if (this.deleteDialog.day.hasOwnProperty("id")) {
        payload.courseId = this.deleteDialog.day.course_id;
        payload.dayId = this.deleteDialog.day.id;
      } else {
        payload.isSaved = false;
        payload.index = this.deleteDialog.index;
        payload.courseId = this.$route.params.courseId;
      }

      this.$store
        .dispatch("flightmode/courses/deleteDay", {
          appId: this.$route.params.id,
          ...payload,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>