<template>
  <v-form @submit.prevent="saveDay" method="post">
    <v-textarea
      label="Summary *"
      v-model="fields.description"
      outlined
      :error="errors.hasOwnProperty('description')"
      :error-messages="errors['description']"
    ></v-textarea>
    <file-pond
      name="video"
      ref="video-upload"
      :server="server"
      accepted-file-types="video/mp4"
      credits="false"
      :files="videoFiles"
      maxFiles="1"
      :onaddfile="onAddVideo"
      :onremovefile="onRemoveVideo"
      labelIdle='<b>Video</b><br />Drag & Drop your video file or <span class="filepond--label-action"> Browse </span>'
    />
    <file-pond
      name="audio"
      ref="audio-upload"
      :server="server"
      accepted-file-types="audio/*"
      credits="false"
      :files="audioFiles"
      maxFiles="1"
      :onaddfile="onAddAudio"
      :onremovefile="onRemoveAudio"
      labelIdle='<b>Audio</b><br />Drag & Drop your audio file or <span class="filepond--label-action"> Browse </span>'
    />
    <v-text-field
      label="Spotify Link"
      v-model="fields.spotify_link"
      outlined
      :error="errors.hasOwnProperty('spotify_link')"
      :error-messages="errors['spotify_link']"
    ></v-text-field>
    <v-btn color="accent" depressed type="submit" :loading="loading"
      >Save Day</v-btn
    >
  </v-form>
</template>

<script>
import { getApiUrl } from "@/utils";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginMediaPreview from "filepond-plugin-media-preview";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview
);

export default {
  components: {
    FilePond,
  },

  props: {
    day: {
      required: false,
      type: Object,
      default: null,
    },
    index: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      loading: false,
      errors: {},
      isEditing: false,
      fields: {
        description: null,
        spotify_link: null,
      },
      audioFiles: [],
      audioFile: null,
      audioChanged: false,
      audioDeleted: false,
      videoFiles: [],
      videoFile: null,
      videoChanged: false,
      videoDeleted: false,
    };
  },

  computed: {
    server() {
      const appId = this.$route.params.id;
      const courseId = this.$route.params.courseId;
      const token = localStorage.getItem("token");

      return {
        url: `${getApiUrl(appId)}/module/flightmode/courses/${courseId}/file`,
        process: null,
        revert: null,
        remove: null,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
    },
  },

  mounted() {
    if (this.day.hasOwnProperty("id") === false) {
      return;
    }

    this.isEditing = true;
    this.fields.description = this.day.description;
    this.fields.spotify_link = this.day.spotify_link;

    if (this.day.video_link) {
      this.setVideoFile(this.day.video_link);
    }

    if (this.day.audio_link) {
      this.setAudioFile(this.day.audio_link);
    }
  },

  methods: {
    saveDay() {
      const appId = this.$route.params.id;
      const courseId = this.$route.params.courseId;
      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        courseId,
        isEditing: this.isEditing,
        index: this.index,
      };

      if (this.isEditing) {
        payload.dayId = this.day.id;
      }

      let formData = new FormData();
      formData.append("description", this.fields.description ?? "");
      formData.append("spotify_link", this.fields.spotify_link ?? "");

      if (this.hasVideoFile()) {
        formData.append("video", this.videoFile);
      } else if (
        this.isEditing === true &&
        this.videoFile === null &&
        this.videoDeleted === true
      ) {
        formData.append("video_delete", true);
      }

      if (this.hasAudioFile()) {
        formData.append("audio", this.audioFile);
      } else if (
        this.isEditing === true &&
        this.audioFile === null &&
        this.audioDeleted === true
      ) {
        formData.append("audio_delete", true);
      }

      this.$store
        .dispatch("flightmode/courses/saveDay", {
          formData,
          ...payload,
        })
        .then(() => {
          this.loading = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: `Day ${this.index + 1} successfully saved!`,
          });
          this.$forceUpdate();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    setAudioFile(file) {
      this.audioFiles.push({
        source: file,
        options: {
          type: "local",
        },
      });
    },
    onAddAudio(error, file) {
      if (error) {
        return;
      }

      if (this.isEditing) {
        this.audioChanged = true;
      }

      this.audioFile = file.file;
    },
    onRemoveAudio() {
      this.audioFile = null;
      this.audioFiles = [];
      this.audioDeleted = true;
    },
    hasAudioFile() {
      if (this.isEditing === true && this.audioChanged === false) {
        return false;
      }

      return this.audioFile !== null;
    },

    setVideoFile(file) {
      this.videoFiles.push({
        source: file,
        options: {
          type: "local",
        },
      });
    },
    onAddVideo(error, file) {
      if (error) {
        return;
      }

      if (this.isEditing) {
        this.videoChanged = true;
      }

      this.videoFiles.push(file);
      this.videoFile = file.file;
    },
    onRemoveVideo() {
      this.videoFile = null;
      this.videoFiles = [];
      this.videoDeleted = true;
    },
    hasVideoFile() {
      if (this.isEditing === true && this.videoChanged === false) {
        return false;
      }

      return this.videoFile !== null;
    },
  },
};
</script>
